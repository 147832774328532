import { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination, useFilters, HeaderGroup, Row } from "react-table";

import { AppConfig, PostalCodeType, getAgencyNameFromUuid } from "../../components/app-config";
import { SyncTextButton } from "../../components/Button";
import { improvedFetch, improvedFetch2 } from "../../components/fetch";
import { LoaderBeforeStaticData } from "../../components/LoaderBeforeStaticData";
import { DefaultColumnFilter, filterTypes } from "../../components/react-table-utils";

import {
  EditableCell,
  MainTable,
  NonEditableCell,
  Td,
  Th,
  TheadTrWithFilters,
  Tr,
  EditableCellYesNo,
} from "../../components/Table";
import { Rythm, Title } from "../../components/Typography";

const fetchPostalCodes = () => improvedFetch2(`/2/geographies/postal-codes-with-agencies`);

const editPostalCode = (
  inseeCode: string,
  postalCode: string,
  data: Record<string, string | null | true | false>
) =>
  improvedFetch(`/postal-codes/${inseeCode}:${postalCode}`, {
    method: "PUT",
    body: JSON.stringify({
      ...data,
      // Hardcode Ouihelp's legal entity for now.
      legal_entity_uuid: "6e61267c-ed52-40de-810d-4903ac0ac6c8",
    }),
  });

const POSTAL_CODES_COLUMNS = [
  {
    Header: "CP",
    accessor: "postal_code",
    sortType: "string",
    maxWidth: 80,
    filter: "startsWith",
  },
  {
    Header: "Ville",
    accessor: "city_name",
    sortType: "string",
  },
  {
    Header: "Nom de l'agence",
    accessor: "agency_uuid",
    sortType: (rowA: any, rowB: any, _columnId: any) => {
      return getAgencyNameFromUuid(rowA.values.agency_uuid) >=
        getAgencyNameFromUuid(rowB.values.agency_uuid)
        ? 1
        : -1;
    },
    filter: "agencyNameFromUuid",
    Cell: (props: any) => (
      <EditableCell
        {...props}
        options={AppConfig.agenciesOptions}
        postNewValue={(newValue, updateData) =>
          editPostalCode(props.row.original.insee_code, props.row.original.postal_code, {
            agency_uuid: newValue,
          }).then(async r => {
            if (!r.ok) {
              throw new Error("Error updating postal code");
            }
            const newPostalCodesData = await r.json();
            updateData(
              newPostalCodesData.insee_code,
              newPostalCodesData.postal_code,
              newPostalCodesData
            );
          })
        }
      />
    ),
  },
  {
    Header: "Coeur",
    accessor: "core_agency",
    maxWidth: 100,
    filter: "yesNo",
    Cell: (props: any) => (
      <EditableCellYesNo
        {...props}
        postNewValue={(newValue: true | false, updateData) => {
          return editPostalCode(props.row.original.insee_code, props.row.original.postal_code, {
            core_agency: newValue,
          }).then(async r => {
            if (!r.ok) {
              throw new Error("Error updating postal code");
            }
            const newPostalCodesData = await r.json();
            updateData(
              newPostalCodesData.insee_code,
              newPostalCodesData.postal_code,
              newPostalCodesData
            );
          });
        }}
      />
    ),
    sortType: (rowA: any, rowB: any, _columnId: any) => {
      return rowA.values.core_agency >= rowB.values.core_agency;
    },
  },
  {
    Header: "Hors-coeur proche",
    accessor: "close_core_agency",
    maxWidth: 150,
    filter: "yesNo",
    Cell: (props: any) => (
      <EditableCellYesNo
        {...props}
        postNewValue={(newValue: true | false, updateData) => {
          return editPostalCode(props.row.original.insee_code, props.row.original.postal_code, {
            close_core_agency: newValue,
          }).then(async r => {
            if (!r.ok) {
              throw new Error("Error updating postal code");
            }
            const newPostalCodesData = await r.json();
            updateData(
              newPostalCodesData.insee_code,
              newPostalCodesData.postal_code,
              newPostalCodesData
            );
          });
        }}
      />
    ),
    sortType: (rowA: any, rowB: any, _columnId: any) => {
      return rowA.values.core_agency >= rowB.values.core_agency;
    },
  },
];

export const PostalCodesSubapp = () => {
  const [postalCodes, setPostalCodes] = useState<Array<PostalCodeType>>([]);
  const [_errorWhileFetching, setErrorWhileFetching] = useState(false);

  const updatePostalCode = useMemo(() => {
    return (inseeCode: string, postalCode: string, newData: PostalCodeType) => {
      console.log(inseeCode, postalCode, newData);
      setPostalCodes(oldPostalCodes => [
        ...oldPostalCodes.filter(v => v.insee_code !== inseeCode || v.postal_code !== postalCode),
        newData,
      ]);
    };
  }, [setPostalCodes]);

  const tableInstance = useTable(
    {
      columns: POSTAL_CODES_COLUMNS,
      data: postalCodes,
      defaultColumn: {
        maxWidth: 0,
        Cell: NonEditableCell,
        Filter: DefaultColumnFilter,
      },
      initialState: { pageSize: 50 },
      filterTypes,
      updateData: updatePostalCode,
      autoResetFilters: false,
    } as any,
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchPostalCodes()
      .then(async postalCodesResponse => {
        if (!postalCodesResponse.ok) {
          throw new Error("Postal codes errored out");
        }
        setErrorWhileFetching(false);
        const json = await postalCodesResponse.json();
        setPostalCodes(json);
      })
      .catch(() => {
        setErrorWhileFetching(true);
      });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance as any;

  return (
    <LoaderBeforeStaticData>
      <Rythm height={1.5}>
        <Title type="h2">Codes postaux</Title>
      </Rythm>
      <MainTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<any>) => (
            <TheadTrWithFilters {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                    style={{
                      ...(column.maxWidth ? { width: column.maxWidth } : {}),
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span style={{ float: "right", fontStyle: "italic" }}>desc</span>
                      ) : (
                        <span style={{ float: "right", fontStyle: "italic" }}>asc</span>
                      )
                    ) : (
                      ""
                    )}
                    {column.canFilter ? column.render("Filter") : null}
                  </Th>
                );
              })}
            </TheadTrWithFilters>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: Row<any>) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => {
                  return (
                    <Td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </tbody>
      </MainTable>
      <Rythm height={0.5} />

      <Rythm height={1}>
        <SyncTextButton onClick={() => gotoPage(0)} text={"<<"} />
        <SyncTextButton onClick={() => previousPage()} text={"<"} style={{ marginLeft: "1em" }} />
        <span style={{ marginLeft: "1em" }}>
          Page {pageIndex + 1} sur {pageOptions.length}
        </span>
        <SyncTextButton onClick={() => nextPage()} text={">"} style={{ marginLeft: "1em" }} />
        <SyncTextButton
          onClick={() => gotoPage(pageCount - 1)}
          text={">>"}
          style={{ marginLeft: "1em" }}
        />
      </Rythm>
    </LoaderBeforeStaticData>
  );
};
