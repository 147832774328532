import { useEffect, useState } from "react";
import { Rythm } from "./Typography";

const BaseComponent = ({
  onClickWrapper,
  onChange,
  onBlur,
  title,
  mode,
  value,
  placeholder,
}: {
  onClickWrapper?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChange?: (newValue: string) => void;
  onBlur?: () => void;
  mode: "view" | "edit" | "network";
  title: string;
  value: string;
  placeholder?: string;
}) => (
  <Rythm height={1.5} onClick={onClickWrapper}>
    <div style={{ width: "100%" }}>
      <b style={{ display: "block" }}>{title}</b>
      {mode === "edit" ? (
        <input
          type="text"
          value={value}
          autoComplete="off"
          name="google-uid-search"
          onChange={e => onChange && onChange(e.target.value)}
          style={{ padding: "4px", width: "100%" }}
          autoFocus
          onBlur={onBlur}
          placeholder={placeholder}
        />
      ) : (
        <span style={{ display: "block", padding: "6px 0" }}>{value || " "}</span>
      )}
    </div>
  </Rythm>
);

export const DataEditableOnBlur = ({
  title,
  initialValue,
  postNewValueOnBlur,
}: {
  title: string;
  initialValue: string;
  postNewValueOnBlur: (s: string) => Promise<Response>;
}) => {
  const [mode, setMode] = useState<"edit" | "view" | "network">("view");
  const [serverValue, setServerValue] = useState(initialValue);
  const [localValue, setLocalValue] = useState(initialValue);

  useEffect(() => {
    if (mode === "view" && serverValue !== localValue) {
      setMode("network");
      postNewValueOnBlur(localValue)
        .then(r => {
          if (!r.ok) {
            throw new Error("Non-OK response");
          }
          setServerValue(localValue);
          setMode("view");
        })
        .catch(() => {
          setLocalValue(serverValue);
          setMode("view");
        });
    }
  }, [mode, serverValue, localValue, postNewValueOnBlur]);

  return (
    <BaseComponent
      title={title}
      value={localValue}
      mode={mode}
      onClickWrapper={e => {
        e.stopPropagation();
        if (mode === "view") {
          setMode("edit");
        }
      }}
      onBlur={() => {
        setMode("view");
      }}
      onChange={newValue => setLocalValue(newValue)}
    />
  );
};

export const DataInput = ({
  title,
  value,
  onChange,
  placeholder,
}: {
  title: string;
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
}) => {
  return (
    <BaseComponent
      title={title}
      value={value}
      mode={"edit"}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export const Data = ({ title, value }: { title: string; value: string }) => {
  return <BaseComponent title={title} value={value} mode={"view"} />;
};
