import { Table as ChakraTable } from "@chakra-ui/react";

import { flexRender, Table } from "@tanstack/react-table";

export function CustomTable<T>({ table, withSorting }: { table: Table<T>; withSorting?: boolean }) {
  return (
    <ChakraTable.Root maxWidth={"100%"} size="sm" style={{ tableLayout: "fixed" }}>
      <ChakraTable.Header>
        {table.getHeaderGroups().map(headerGroup => (
          <ChakraTable.Row key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <ChakraTable.ColumnHeader
                key={header.id}
                style={{ width: header.getSize() }}
                onClick={withSorting ? header.column.getToggleSortingHandler() : undefined}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                {withSorting
                  ? {
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string]
                  : null}
              </ChakraTable.ColumnHeader>
            ))}
          </ChakraTable.Row>
        ))}
      </ChakraTable.Header>
      <ChakraTable.Body>
        {table.getRowModel().rows.map(row => (
          <ChakraTable.Row key={row.id}>
            {row.getVisibleCells().map(cell => (
              <ChakraTable.Cell
                key={cell.id}
                style={{
                  width: cell.column.getSize(),
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </ChakraTable.Cell>
            ))}
          </ChakraTable.Row>
        ))}
      </ChakraTable.Body>
    </ChakraTable.Root>
  );
}
