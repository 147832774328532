import { useState } from "react";
import { BsArrowUp } from "react-icons/bs";
import { Table } from "../../../components/Table";
import { EditableField } from "../../../components/editable-field";
import { Department } from "../../../services/department";
import { FinancialHelp, FinancialHelpType, GeoAreaType } from "../../../services/financial-help";
import { GeoAreaLabel, HelpLabel } from "../../../services/financial-help/translation";
import { LegalEntity } from "../../../services/legal-entity";
import { FinancialHelpTableExtendedRow } from "./FinancialHelpTableExtendedRow";

type FinancialHelpTableRowProps = {
  datum: FinancialHelp;
  departments: Department[];
  legalEntities: LegalEntity[];
  onEdit: (uuid: string, changes: Partial<FinancialHelp>) => void;
};

export const FinancialHelpTableRow = ({
  datum,
  departments,
  legalEntities,
  onEdit,
}: FinancialHelpTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Table.bodyRow expanded={isExpanded} id={datum.uuid}>
        <Table.cell>
          <EditableField.input value={datum.name} onEdit={name => onEdit(datum.uuid, { name })} />
        </Table.cell>
        <Table.cell>
          <EditableField.select
            value={datum.legal_entity_uuid}
            onEdit={legal_entity_uuid => onEdit(datum.uuid, { legal_entity_uuid })}
            options={legalEntities.map(legalEntity => ({
              label: legalEntity.name,
              value: legalEntity.uuid,
            }))}
          />
        </Table.cell>
        <Table.cell>
          <EditableField.select
            value={datum.help_type}
            onEdit={help_type => onEdit(datum.uuid, { help_type })}
            options={Object.entries(HelpLabel).map(([value, label]) => ({
              value: value as FinancialHelpType,
              label,
            }))}
          />
        </Table.cell>
        <Table.cell>
          <EditableField.select
            value={datum.geo_area_type}
            onEdit={geo_area_type => onEdit(datum.uuid, { geo_area_type })}
            options={Object.entries(GeoAreaLabel).map(([value, label]) => ({
              value: value as GeoAreaType,
              label,
            }))}
          />
        </Table.cell>
        <Table.cell>
          <EditableField.multiSelect
            value={datum.departments}
            onEdit={departments => onEdit(datum.uuid, { departments })}
            options={departments.map(d => ({
              label: d.name,
              value: d.id,
            }))}
          />
        </Table.cell>
        <Table.cell>
          <button
            style={{ border: "none", borderRadius: "50%", height: 25, width: 25 }}
            onClick={() => setIsExpanded(p => !p)}
          >
            <BsArrowUp
              style={{ transform: `rotate(${isExpanded ? "0" : "180"}deg)` }}
              width={30}
              height={30}
            />
          </button>
        </Table.cell>
      </Table.bodyRow>
      {isExpanded && <FinancialHelpTableExtendedRow datum={datum} onEdit={onEdit} />}
    </>
  );
};
