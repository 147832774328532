import { Table } from "../../../components/Table";
import { Department } from "../../../services/department";
import { FinancialHelpType, GeoAreaType } from "../../../services/financial-help";
import { GeoAreaLabel, HelpLabel } from "../../../services/financial-help/translation";
import { LegalEntity } from "../../../services/legal-entity";
import { FinancialHelpFilters } from "./types";

type FinancialHelpTableHeaderProps = {
  filters: FinancialHelpFilters;
  setFilters: React.Dispatch<React.SetStateAction<FinancialHelpFilters>>;
  departments: Department[];
  legalEntities: LegalEntity[];
};

export const FinancialHelpTableHeader = ({
  filters,
  setFilters,
  departments,
  legalEntities,
}: FinancialHelpTableHeaderProps) => {
  return (
    <Table.header>
      <Table.headerRow>
        <Table.headerCell>
          <div>Nom</div>
          <input
            placeholder="Saisissez un nom"
            value={filters.name}
            onChange={e => setFilters(prev => ({ ...prev, name: e.target.value }))}
          />
        </Table.headerCell>
        <Table.headerCell>
          <div>Entité</div>
          <select
            value={filters.legal_entity_uuid}
            onChange={e =>
              setFilters(prev => ({
                ...prev,
                legal_entity_uuid: e.target.value,
              }))
            }
          >
            <option selected disabled>
              Selectionner une entité
            </option>
            {legalEntities.map(legalEntity => (
              <option key={legalEntity.uuid} value={legalEntity.uuid}>
                {legalEntity.name}
              </option>
            ))}
          </select>
        </Table.headerCell>
        <Table.headerCell>
          <div>Type d'aide</div>
          <select
            value={filters.help_type}
            onChange={e =>
              setFilters(prev => ({
                ...prev,
                help_type: e.target.value as FinancialHelpType,
              }))
            }
          >
            <option selected disabled>
              Selectionner un type d'aide
            </option>
            {Object.entries(HelpLabel).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </Table.headerCell>
        <Table.headerCell>
          <div>Portée</div>
          <select
            value={filters.geo_area_type}
            onChange={e =>
              setFilters(prev => ({
                ...prev,
                geo_area_type: e.target.value as GeoAreaType,
              }))
            }
          >
            <option selected disabled>
              Selectionner une portée
            </option>
            {Object.entries(GeoAreaLabel).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </Table.headerCell>
        <Table.headerCell>
          <div>Départements</div>
          <select
            value={filters.departments}
            onChange={e =>
              setFilters(prev => ({
                ...prev,
                departments: e.target.value,
              }))
            }
          >
            <option selected disabled>
              Selectionner un département
            </option>
            {departments.map(department => (
              <option key={department.id} value={department.id}>
                {department.name}
              </option>
            ))}
          </select>
        </Table.headerCell>
        <Table.headerCell style={{ width: 40 }} />
      </Table.headerRow>
    </Table.header>
  );
};
