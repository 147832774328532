import { useMsal } from "@azure/msal-react";
import { Container, Heading, Stack, Text, Button, Center } from "@chakra-ui/react";

import { OuterShell as OuterShell2 } from "../../components/2/Shell";
import { ApiScope } from "../../components/app-config";

export const WaitingForNigol = () => {
  const { instance, inProgress } = useMsal();

  const isInLoginProcess = inProgress === "login";

  return (
    <OuterShell2>
      <Container maxW="md" py={{ base: "12", md: "24" }}>
        <Stack gap="8">
          <Stack gap="6">
            <Center fontSize="6xl">🧠</Center>

            <Stack gap={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "3xl" }}>DSI + IT = DSIT</Heading>
              <Text color="fg.muted">
                Où les collaborateurs rejoignent leurs jobs & agences et où les codes postaux
                prennent vie.
              </Text>
            </Stack>
          </Stack>

          <>
            <Button
              variant="outline"
              onClick={() => {
                instance
                  .loginPopup({
                    scopes: ["openid"],
                    extraScopesToConsent: [ApiScope],
                  })
                  .then(r => {
                    instance.setActiveAccount(r.account);
                  });
              }}
              disabled={isInLoginProcess}
            >
              {isInLoginProcess ? "Connexion en cours" : "Se connecter avec Microsoft"}
            </Button>

            <Stack gap="3" justify="center">
              <Text textStyle="sm" color="fg.muted" textAlign="center">
                L'accès à cette interface est limité, si tu ne peux pas t'y connecter, rapproche-toi
                de ton manager.
              </Text>
            </Stack>
          </>
        </Stack>
      </Container>
    </OuterShell2>
  );
};
