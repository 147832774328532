import "typeface-roboto-mono";

import { useEffect } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { Shell } from "./components/Shell";
import { Shell as Shell2, ShellWithSidebar as Shell2WithSidebar } from "./components/2/Shell";
import {
  AgencyType,
  AppConfig,
  JobType,
  staticDataPromiseResolver,
  ZoneType,
} from "./components/app-config";

import { UsersSubapp } from "./subapps/users";
import { WaitingForNigol } from "./subapps/loginish";
import { ZonesAndAgenciesSubapp } from "./subapps/zones-and-agencies";
import { PostalCodesSubapp } from "./subapps/postal-codes";
import { PermerSubapp } from "./subapps/permer";
import { improvedFetch, improvedFetch2 } from "./components/fetch";
import { Microsoft } from "./subapps/2/microsoft";
import { Onboarding } from "./subapps/2/onboarding";
import { FinancialHelpTable } from "./subapps/financial-help/financial-help-table";
import { CreateFinancialHelp } from "./subapps/financial-help/create-financial-help";

const router = createBrowserRouter([
  {
    path: "/2",
    element: <Shell2 />,
    children: [
      {
        path: "",
        element: <Shell2WithSidebar />,
        children: [
          {
            path: "dsi/microsoft",
            element: <Microsoft />,
          },
        ],
      },
      {
        path: "onboarding",
        element: <Onboarding />,
      },
    ],
  },
  {
    path: "/",
    element: <Shell />,
    children: [
      {
        path: "users",
        element: <UsersSubapp />,
      },
      {
        path: "zones-and-agencies",
        element: <ZonesAndAgenciesSubapp />,
      },
      {
        path: "financial-helps",
        element: <FinancialHelpTable />,
      },
      {
        path: "financial-helps/create",
        element: <CreateFinancialHelp />,
      },
      {
        path: "postal-codes",
        element: <PostalCodesSubapp />,
      },
      {
        path: "permer",
        element: <PermerSubapp />,
      },
    ],
  },
]);

const AppInner = () => {
  useEffect(() => {
    const jobsPromise = improvedFetch(`/static-jobs`).then(async jobsResponse => {
      const jobs: JobType[] = await jobsResponse.json();
      return jobs;
    });

    const zonesPromise = improvedFetch2(`/2/geographies/zones`).then(async zonesResponse => {
      const zones: ZoneType[] = await zonesResponse.json();
      return zones;
    });

    const agenciesPromise = improvedFetch2(`/2/geographies/agencies`).then(
      async agenciesResponse => {
        const agencies: AgencyType[] = await agenciesResponse.json();
        return agencies;
      }
    );

    Promise.all([jobsPromise, zonesPromise, agenciesPromise]).then(([jobs, zones, agencies]) => {
      AppConfig.jobs = jobs;
      AppConfig.zones = zones;
      AppConfig.agencies = agencies;
      AppConfig.agenciesOptions = agencies.map(a => ({
        value: a.agency_uuid,
        label: a.agency_name,
        assignable: true,
      }));
      staticDataPromiseResolver();
    });
  }, []);

  return <RouterProvider router={router} />;
};

export const AppRoot = () => (
  <MsalProvider instance={AppConfig.publicClientApplication}>
    <UnauthenticatedTemplate>
      <WaitingForNigol />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <AppInner />
    </AuthenticatedTemplate>
  </MsalProvider>
);
