"use client";

import { ChakraProvider, defaultConfig } from "@chakra-ui/react";
import { ColorModeProvider, type ColorModeProviderProps } from "./color-mode";

// Font customization starts
import { createSystem, defineConfig } from "@chakra-ui/react";

const config = defineConfig({
  theme: {
    tokens: {
      fonts: {
        body: { value: `"Open Sans Variable", ${defaultConfig.theme?.tokens?.fonts?.body.value}` },
        heading: {
          value: `"Spline Sans Variable", ${defaultConfig.theme?.tokens?.fonts?.heading.value}`,
        },
      },
    },
  },
});

const system = createSystem(defaultConfig, config);
// Font customization ends

export function Provider(props: ColorModeProviderProps) {
  return (
    <ChakraProvider value={system}>
      <ColorModeProvider {...props} />
    </ChakraProvider>
  );
}
