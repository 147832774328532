// import { Box, Text } from "@chakra-ui/react";

import { Table } from "../../../components/Table";
import { EditableField } from "../../../components/editable-field";
import { FinancialHelp } from "../../../services/financial-help";
import { EditionContainer } from "./styles";

type FinancialHelpTableExtendedRowProps = {
  datum: FinancialHelp;
  onEdit: (uuid: string, changes: Partial<FinancialHelp>) => void;
};

const Text = ({ children }: { children: any }) => <p style={{ fontWeight: "bold" }}>{children}</p>;

export const FinancialHelpTableExtendedRow = ({
  datum,
  onEdit,
}: FinancialHelpTableExtendedRowProps) => {
  return (
    <Table.extendedBodyRow>
      <Table.cell colSpan={6}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <EditionContainer>
            <Text>DSIT UUID</Text>
            {datum.uuid}
          </EditionContainer>

          <EditionContainer>
            <Text>Sous forme de tiers payant</Text>
            <EditableField.select
              value={datum.is_third_party_payer.toString()}
              options={[
                { value: "true", label: "oui" },
                { value: "false", label: "non" },
              ]}
              onEdit={is_third_party_payer =>
                onEdit(datum.uuid, {
                  is_third_party_payer: is_third_party_payer == "true",
                })
              }
            />
          </EditionContainer>

          <EditionContainer>
            <Text>GIR minimum</Text>
            <EditableField.select
              value={String(datum.min_gir)}
              onEdit={min_gir => onEdit(datum.uuid, { min_gir: +min_gir })}
              options={Array(6)
                .fill(null)
                .map((_, index) => ({
                  value: (index + 1).toString(),
                  label: (index + 1).toString(),
                }))}
            />
          </EditionContainer>

          <EditionContainer>
            <Text>GIR maximum</Text>
            <EditableField.select
              value={String(datum.max_gir)}
              onEdit={max_gir => onEdit(datum.uuid, { max_gir: +max_gir })}
              options={Array(6)
                .fill(null)
                .map((_, index) => ({
                  value: (index + 1).toString(),
                  label: (index + 1).toString(),
                }))}
            />
          </EditionContainer>

          <EditionContainer>
            <Text>Taux de prise en charge maximum</Text>
            <EditableField.input
              type="number"
              value={String(datum.max_care_rate ? datum.max_care_rate / 100 : "")}
              onEdit={max_care_rate =>
                onEdit(datum.uuid, {
                  max_care_rate: max_care_rate ? parseFloat(max_care_rate) * 100 : null,
                })
              }
            />
          </EditionContainer>

          <EditionContainer>
            <Text>URL Slite</Text>
            <EditableField.input
              value={datum.slite_url || ""}
              onEdit={slite_url => onEdit(datum.uuid, { slite_url })}
            />
          </EditionContainer>

          <EditionContainer>
            <Text>Montant horaire pris en charge en semaine</Text>
            <EditableField.input
              type="number"
              value={String(
                datum.week_amount_per_hour_cents ? datum.week_amount_per_hour_cents / 100 : ""
              )}
              onEdit={week_amount_per_hour_cents =>
                onEdit(datum.uuid, {
                  week_amount_per_hour_cents: week_amount_per_hour_cents
                    ? parseFloat(week_amount_per_hour_cents) * 100
                    : null,
                })
              }
            />
          </EditionContainer>
          <EditionContainer>
            <Text>Montant horaire pris en charge le samedi</Text>
            <EditableField.input
              type="number"
              value={String(
                datum.saturday_amount_per_hour_cents
                  ? datum.saturday_amount_per_hour_cents / 100
                  : ""
              )}
              onEdit={saturday_amount_per_hour_cents =>
                onEdit(datum.uuid, {
                  saturday_amount_per_hour_cents: saturday_amount_per_hour_cents
                    ? parseFloat(saturday_amount_per_hour_cents) * 100
                    : null,
                })
              }
            />
          </EditionContainer>
          <EditionContainer>
            <Text>Montant horaire pris en charge le dimanche</Text>
            <EditableField.input
              type="number"
              value={String(
                datum.sunday_amount_per_hour_cents ? datum.sunday_amount_per_hour_cents / 100 : ""
              )}
              onEdit={sunday_amount_per_hour_cents =>
                onEdit(datum.uuid, {
                  sunday_amount_per_hour_cents: sunday_amount_per_hour_cents
                    ? parseFloat(sunday_amount_per_hour_cents) * 100
                    : null,
                })
              }
            />
          </EditionContainer>
          <EditionContainer>
            <Text>Description</Text>
            <EditableField.textArea
              value={datum.description || ""}
              onEdit={description => onEdit(datum.uuid, { description })}
            />
          </EditionContainer>
        </div>
      </Table.cell>
    </Table.extendedBodyRow>
  );
};
