import { useEffect, useRef, useState } from "react";
import { EditableCellReadProps, EditableCellWriteProps } from "./types";

export type EditableBaseCellProps<T> = {
  value: T;
  ReadComponent: (props: EditableCellReadProps<T>) => React.ReactNode;
  WriteComponent: (props: EditableCellWriteProps<T>) => React.ReactNode;
  onEdit: (value: T) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function EditableField<T>({
  value,
  ReadComponent,
  WriteComponent,
  onEdit,
  ...cellProps
}: EditableBaseCellProps<T>) {
  const [isEditing, setIsEditing] = useState(false);
  const editionRef = useRef<HTMLElement>(null);
  const [localValue, setLocalValue] = useState(value);
  const timeoutRef = useRef<number>();

  const handleClick = (e: React.MouseEvent) => {
    if (!isEditing) {
      e.stopPropagation();
      setLocalValue(value);
      setIsEditing(true);
    }
  };

  const handleFocus = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleBlur = () => {
    if (isEditing)
      setTimeout(() => {
        onEdit(localValue);
        setIsEditing(false);
      }, 0);
  };

  useEffect(() => {
    if (isEditing) editionRef.current?.focus();
  }, [editionRef, isEditing]);

  return (
    <div {...cellProps} onClick={handleClick} onFocus={handleFocus} onBlur={handleBlur}>
      {!isEditing && <ReadComponent value={value} />}
      {isEditing && (
        <WriteComponent inputRef={editionRef} value={localValue} setValue={setLocalValue} />
      )}
    </div>
  );
}
